import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import GeneralTitle from "../../Components/GeneralTitle";
import Loader from "../../Components/Loader";
import Navbar from "../../Components/Navbar";
import text_truncate from "../../Components/TextTruncate";
import { CATIMAGE_BASE_URL, HomeIMAGE_BASE_URL } from "../../Constants";
import { displayLoader } from "../../Store/action/loader.action";
import { getAllCategories } from "../../Store/api/category.api";
import { StateType } from "../../Store/reducer/index.reducer";
import { formatTime } from "../Generic";

const Category = ({ subDomain }: any) => {
    const dispatch: any = useDispatch();
    const homeData = useSelector(
        (state: StateType) => state.frontProfile?.frontProfile
    );
    const { categoryRes, loader } = useSelector(
        (state: StateType) => state.category
    );
    const canOrder = useSelector(
        (state: StateType) => state.category.categoryRes.can_order
    );
    const navigate = useNavigate();

    const [background, setBackground] = useState("/Images/catcover.png");
    useEffect(() => {
        dispatch(displayLoader(true));
        if (subDomain) {
            dispatch(
                getAllCategories(
                    subDomain,
                    device_time,
                    { navigate: navigate },
                    day
                )
            );
        }
    }, [dispatch, subDomain]);
    // const firstrender = useRef(true);
    useEffect(() => {
        // if (!firstrender.current) {
        if (homeData?.profile?.banner)
            setBackground(`${HomeIMAGE_BASE_URL}/${homeData?.profile?.banner}`);
        // }
        // firstrender.current = false;
    }, [homeData]);
    useEffect(() => {
        if (subDomain) {
            GeneralTitle(subDomain);
        }
    }, [subDomain]);

    return (
        <>
            <Navbar showArrow showVenue={subDomain} />
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="whole_wrapper">
                    {/* <div className="restaurant_closed">
                        <span className="closed_notification animateOpen">
                            Restaurant Closed
                        </span>
                    </div> */}
                    <div className="category">
                        <div
                            className="category-cover"
                            // style={{
                            //     background: `url(${background}) no-repeat center center/cover`,
                            // }}
                        >
                            <img
                                src={
                                    homeData?.profile?.banner == "/images/user/"
                                        ? "../Images/cover.png"
                                        : `${HomeIMAGE_BASE_URL}/${homeData?.profile?.banner}`
                                }
                            />
                        </div>
                        <div className="delivery_content">
                            <div className="delivery_category">
                                {canOrder == 0
                                    ? "Restaurant Closed"
                                    : "Visual Menu"}
                            </div>
                        </div>
                        <div className="top-content ">View Menu</div>
                        <div className="category_container">
                            {categoryRes?.categories?.map(
                                (item: any, index: number) => {
                                    return (
                                        <div className="food-items" key={index}>
                                            <Link
                                                to={`/listingmenu/${item.id}/${item.restaurant_id}`}
                                            >
                                                <div className="food-itemsimg">
                                                    <img
                                                        src={
                                                            item.photo
                                                                ? `${CATIMAGE_BASE_URL}/${item.photo}`
                                                                : "/Images/noimg-1.svg"
                                                        }
                                                        alt={item.photo}
                                                    />
                                                </div>
                                                {item.can_order == 0 && (
                                                    <>
                                                        <div className="order_status">
                                                            <div>
                                                                Ordering not
                                                                available
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <h2 className="item_name">
                                                    <div>
                                                        {text_truncate({
                                                            str: item.name,
                                                            length: 18,
                                                            ending: null,
                                                        })}
                                                    </div>
                                                    {item.can_order == 0 && (
                                                        <div className="availiable_time">
                                                            (till{" "}
                                                            {formatTime(
                                                                item.end_time
                                                            )}
                                                            )
                                                        </div>
                                                    )}
                                                </h2>
                                            </Link>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default Category;

const date = new Date();
export const device_time =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
//weekday
const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
const d = new Date();
export const day = weekday[d.getDay()];

export const converTime = (time: any) => {
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    let part = hour > 12 ? "pm" : "am";

    min = (min + "").length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + "").length == 1 ? `0${hour}` : hour;
    // let hours12 = parseInt(hour, 10);
    // const amPm = hours12 >= 12 ? "PM" : "AM";

    // hours12 %= 12;
    // hours12 = hours12 || 12; // Replace 0 with 12 for 12 AM/PM

    // const time12Hour = `${hours12}:${min} ${amPm}`;
    // return time12Hour;

    return `${hour}:${min} ${part}`;
};
