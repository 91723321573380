import React, { useState, useEffect } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import type { DrawerProps } from "antd/es/drawer";
import { Drawer, Space } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StateType } from "../../Store/reducer/index.reducer";
import { getFrontApi } from "../../Store/api/front.api";
import { getAllCategories } from "../../Store/api/category.api";
import { day, device_time } from "../../Pages/Category";
import { HomeIMAGE_BASE_URL } from "../../Constants";

interface NavProps {
    language?: string;
    title?: string;
    showLogo?: boolean;
    showVenue?: string;
    showArrow?: boolean;
    showMenuBar?: boolean;
}
const Navbar: React.FC<NavProps> = (props: NavProps) => {
    const [visible, setVisible] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [countryVal, setCountryVal] = useState(null);
    const [placement] = useState<DrawerProps["placement"]>("left");
    let navigate = useNavigate();
    const { title, language, showVenue, showArrow, showMenuBar } = props;
    const dispatch = useDispatch<any>();
    const homeData = useSelector(
        (state: StateType) => state.frontProfile?.frontProfile
    );
    const { subDomain }: any = props;

    const showDrawer = () => {
        setVisible(true);
        setDrawerOpen(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const goBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        if (showVenue != undefined && showVenue != null) {
            dispatch(getFrontApi(showVenue, { navigate: navigate }));
            dispatch(
                getAllCategories(
                    showVenue,
                    device_time,
                    { navigate: navigate },
                    day
                )
            );
        }
    }, [showVenue]);

    return (
        <>
            <div className="navbar">
                <div className="navbar__menu">
                    {showMenuBar && (
                        <Space>
                            <HiMenuAlt1 fontSize={24} onClick={showDrawer} />
                        </Space>
                    )}
                    {showArrow && (
                        <div className="left-arrow">
                            <BsArrowLeft
                                fontSize={16}
                                className="left-arrow__icon"
                                onClick={goBack}
                            />
                        </div>
                    )}
                    {showVenue && (
                        <div className="navbar__menu__venuename">
                            <Link to={showVenue ? `/` : ""}>
                                {homeData?.profile?.name}
                            </Link>
                        </div>
                    )}
                    <div className="navbar__menu__title">{title}</div>
                    <div className="navbar__menu__language">{language}</div>
                </div>
                <Drawer
                    placement={placement}
                    width={250}
                    onClose={onClose}
                    open={visible}
                    className="navbar__sidebar"
                >
                    <div className="navbar__sidebar__topcontent">
                        <div className="navbar__sidebar__topcontent__logo">
                            <img src="/Images/logo_myordr.svg" />
                        </div>
                        <ul>
                            <li>
                                <Link to="/">Learn more about ORDR.</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar__sidebar__content">
                        <p className="content-II">
                            {homeData?.profile?.country}
                        </p>
                        <p className="content-II">{homeData?.profile?.phone}</p>
                        <p className="content-II">{homeData?.profile?.email}</p>
                        {/* <div className="navbar__sidebar__content__lang">
                            <span>EN</span>
                            <span>DE</span>
                            <span>FR</span>
                            <span>EL</span>
                            <span>CN</span>
                            <span>ES</span>
                            <span>IT</span>
                        </div> */}
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default Navbar;
