export type $FIXME = any;
export const url = (): { url: any; subdomain: string } => {
    const hostname = window.location.host;
    var urlArray = hostname.split(".")[0];
    var url;
    if (urlArray.split("-").slice(-1).includes("uat")) {
        url = process.env.REACT_APP_VMWEBAPI_URL_UAT;
        urlArray = urlArray.slice(0, -4);
    } else if (urlArray.split("-").slice(-1).includes("staging")) {
        url = process.env.REACT_APP_VMWEBAPI_URL_STAGING;
        urlArray = urlArray.slice(0, -8);
    } else {
        url = process.env.REACT_APP_VMWEBAPI_URL;
    }
    return {
        url: url,
        subdomain: urlArray,
    };
};
export const VMAPI_BASE_URL = `${url().url}/api/v1`;
export const OffIMAGE_BASE_URL = `${url().url}/images/offer`;
export const CATIMAGE_BASE_URL = `${url().url}/images/category`;
export const ToolsIMAGE_BASE_URL = `${url().url}/images/tools`;

export const PRODCUCTIMAGE_BASE_URL = `${url().url}/images/product`;
export const HomeIMAGE_BASE_URL = `${url().url}`;
