import React from "react";

const GeneralTitle = (newTitle: any) => {
    // return (document.title = newTitle);

    const arr = newTitle.split(" ");

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return (
        <div className="meta-title">
            {(document.title = `${str2} - Explore our menu`)}
        </div>
    );
};
export default GeneralTitle;
