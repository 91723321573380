import { Dispatch } from "redux";
import { getOffer } from "../action/offer.action";
import { displayLoader } from "../action/loader.action";
import api from "./index.api";

export const getOfferApi = (slug: any) => (dispatch: Dispatch) => {
    api.get(`/offer/${slug}`)
        .then((response) => {
            dispatch(displayLoader(false));

            dispatch(getOffer(response.data?.data));
        })
        .catch((err) => {
            dispatch(displayLoader(false));
        });
};
const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case "yuan":
            return "CNY";
        case "ringgit":
            return "MYR";
        case "thai-baht":
            return "THB";
        case "myanmar-kyat":
            return "MMK";
        default:
            return "$";
    }
};
