import React, { useState } from "react";
import { Link } from "react-router-dom";
import CardList from "../../Helpers/CardList";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/autoplay";
import { ToolsIMAGE_BASE_URL } from "../../Constants";
import { useSelector } from "react-redux";
import { StateType } from "../../Store/reducer/index.reducer";
import { Skeleton } from "antd";

type AboutProps = {
    content?: string;
    image?: any;
    location?: any;
    phoneNum?: any;
    email?: any;
    locationAdd?: any;
    iFrameSrc?: any;
};
const AboutUs = (props: AboutProps) => {
    const { content, image, email } = props;
    return (
        <>
            <div className="aboutus-images">
                <img
                    src={`${ToolsIMAGE_BASE_URL}/${image}`}
                    alt="about-us-image"
                    className="aboutus-images"
                />
            </div>
            <div className="aboutus-content">
                {/* <h2>About Us</h2> */}
                <p>{content}</p>
            </div>
        </>
    );
};

const Address = (props: AboutProps) => {
    const { location, phoneNum, email, locationAdd, iFrameSrc } = props;
    // const { loader } = useSelector((state: StateType) => state.frontProfile);
    const [loading, setLoading] = useState();

    return (
        <>
            <div className="address-container">
                {/* <h2>Address</h2> */}
                <div className="google-map-code">
                    {loading ? <Skeleton active /> : null}
                    <iframe
                        src={iFrameSrc}
                        width="100%"
                        height="320px"
                        style={{ borderRadius: 12, border: 0 }}
                        aria-hidden="false"
                    ></iframe>
                </div>
                <div className="location">
                    <span>{locationAdd}</span>
                    <span>
                        <img
                            src="../Images/locationicon.svg"
                            alt="location-icon"
                        />
                    </span>
                </div>
                <div className="address-content">
                    <h3>Address</h3>
                    <div className="address-detail">{location}</div>
                </div>
                <div className="address-content">
                    <h3>Phone Number</h3>
                    <div className="address-detail">{phoneNum}</div>
                </div>
                <div className="address-content">
                    <h3>Email</h3>
                    <div className="address-detail">{email}</div>
                </div>
            </div>
        </>
    );
};
export { AboutUs, Address };
