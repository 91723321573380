import { $FIXME } from "../../Constants";
import { GET_BTNLOADER, GET_LOADER, PROFILEACTION } from "../const";
import {
    FrontOperationsType,
    FrontPositionsType,
    FrontType,
    OfferFrontType,
} from "../type/Front.type";
export interface FrontTypeReducer {
    frontProfile: FrontType;
    frontOffer: Array<OfferFrontType>;
    frontPosition: Array<FrontPositionsType>;
    frontOperation: Array<FrontOperationsType>;
    loader: boolean;
    btnLoader: boolean;
}
const initialState: FrontTypeReducer = {
    frontProfile: {} as FrontType,
    frontOffer: [],
    frontPosition: [],
    frontOperation: [],
    loader: true,
    btnLoader: false,
};

const FrontProfileReducer = (state = initialState, action: $FIXME) => {
    switch (action.type) {
        case PROFILEACTION.GET_ALLFrontData:
            return {
                ...state,
                frontOffer: action.payload,
                frontProfile: action.payload,
                frontPosition: action.payload,
                frontOperation: action.payload,
            };
        case GET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        case GET_BTNLOADER:
            return {
                ...state,
                btnLoader: action.payload,
            };
        default:
            return state;
    }
};
export default FrontProfileReducer;
