import React, { useState } from "react";
import { Rate } from "antd";
import Navbar from "../../Components/Navbar";
import { FeedbackList } from "../../Helpers/FeedbackList";
import { $FIXME } from "../../Constants";
import Button from "../../Components/Button";
import Footer from "../../Components/Footer";
import { getFeedbackApi } from "../../Store/api/feedback.api";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GeneralTitle from "../../Components/GeneralTitle";

interface IFeedback {
    scr?: string;
    feedback?: string;
    val?: string;
}
const Feedback = ({ subDomain }: any) => {
    const [feedback, setFeedback] = useState("");
    const [selected, setSelected] = useState(false);
    const [feedbackTab, setFeedbackTab] = React.useState<any>("");
    const [rate, setRate] = useState(0);
    const [err, setErr] = useState(false);
    const dispatch = useDispatch<any>();
    // const { currentVenue }: any = useParams();
    subDomain && GeneralTitle(subDomain);
    let navigate = useNavigate();
    const handleChange = (e: any) => {
        setFeedback(e.target.value);
        if (e.target.value == "") {
            setErr(true);
        } else if (feedback.length != 0) {
            setErr(false);
        } else {
            setErr(false);
        }
    };
    const handleClickTab = (item: any) => {
        setFeedbackTab(item.toLowerCase());
        // if (feedbackTab.includes(item)) {
        //     setFeedbackTab(feedbackTab?.filter((i: any) => i !== item));
        //     setSelected(!selected);
        // } else {
        //     setFeedbackTab([...feedbackTab, item?.toLowerCase()]);
        // }
    };
    const handleSubmit = (values: any) => {
        values.preventDefault();
        if (feedback.length) {
            const toSend = {
                feedback: feedback,
                good: feedbackTab,
                star: rate,
                slug: subDomain,
            };
            let data = toSend;
            subDomain &&
                dispatch(
                    getFeedbackApi(data, { subDomain, navigate: navigate })
                );
            setErr(false);
        } else {
            setErr(true);
        }
    };

    return (
        <>
            <Navbar title="Feedback" showArrow />
            <div className="whole_wrapper">
                <div className="feedback_page container">
                    <h2>Please rate our venue</h2>
                    <form onSubmit={handleSubmit}>
                        <Rate
                            // defaultValue={2.5}
                            onChange={setRate}
                            value={rate}
                        />
                        <div className="feedback_mid">
                            <h2>What was good?</h2>
                            <div className="feedbacks_wrapper">
                                {FeedbackList?.map(
                                    (_data: IFeedback, i: number) => {
                                        return (
                                            <>
                                                <div
                                                    className={
                                                        // feedbackTab.includes(
                                                        //     _data.val?.toLowerCase()
                                                        // )
                                                        feedbackTab ==
                                                        _data.val?.toLowerCase()
                                                            ? "feedback_content active"
                                                            : "feedback_content inactive"
                                                    }
                                                    key={i}
                                                    onClick={() =>
                                                        handleClickTab(
                                                            _data.val?.toLowerCase()
                                                        )
                                                    }
                                                >
                                                    <div className="fcontent_img">
                                                        <img
                                                            src={_data.scr}
                                                            alt="image"
                                                        />
                                                    </div>

                                                    <div>{_data.feedback}</div>
                                                </div>
                                            </>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className="ftextarea_wrapper">
                            <h2>Any Feedback?</h2>
                            <textarea
                                className="feedback_textarea"
                                rows={2}
                                onChange={handleChange}
                                name="feedback"
                                value={feedback}
                            />
                            {err && (
                                <p className="errmsg">Feedback is required</p>
                            )}
                        </div>
                        <Button btnType="primary">Submit</Button>
                    </form>
                    <div className="page_footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Feedback;
