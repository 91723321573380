import { $FIXME } from "../../Constants";
import { GET_LOADER, GET_SOCIALMEDIA, PROFILEACTION } from "../const";
import { SocialMediaType } from "../type/socialMedia.type";
export interface SocialTypeReducer {
    socialMedia: Array<SocialMediaType>;
    loader: boolean;
}
const initialState: SocialTypeReducer = {
    socialMedia: [],
    loader: true,
};

const socialMediaReducer = (state = initialState, action: $FIXME) => {
    switch (action.type) {
        case GET_SOCIALMEDIA:
            return {
                ...state,
                socialMedia: action.payload,
            };
        case GET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        default:
            return state;
    }
};
export default socialMediaReducer;
