import { GET_LOADER, GET_TAG } from "../const";
import { TagType } from "../type/tag.type";

export interface TagReducer {
    tags: Array<TagType>;
}
const inititalState: TagReducer = {
    tags: [],
    // loader: false,
};

const tagReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case GET_TAG:
            return {
                ...state,
                tags: payload,
            };
        case GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default tagReducer;
