import React from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";

const ContentNotFound = () => {
    return (
        <>
            <div className="whole_wrapper">
                <div className="error-container">
                    <div className="text-container">
                        <h2>ERROR</h2>
                        <h1>4O4</h1>
                        <h3>Oops! Something went wrong.</h3>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ContentNotFound;
