import { $FIXME } from "../../Constants";
import { PROFILEACTION } from "../const";
import { FrontType } from "../type/Front.type";

export const getFront = (data: FrontType) => {
    return {
        type: PROFILEACTION.GET_ALLFrontData,
        payload: data,
    };
};
