import { combineReducers } from "redux";
import categoryReducer, { CategoryReducer } from "./category.reducer";
import detailProductReducer, {
    DetailProductReducer,
} from "./detailProduct.reducer";
import feedbackReducer, { FeedbackReducerType } from "./feedback.reducer";
import FrontProfileReducer, { FrontTypeReducer } from "./front.reducer";
import listingMenuReducer, { ListingMenuReducer } from "./listingMenu.reducer";
import { OfferReducer, OfferTypeReducer } from "./offer.reducer";
import ProfileReducer, { ProfileFrontTypeReducer } from "./profile.reducer";
import socialMediaReducer, { SocialTypeReducer } from "./socialMedia.reducer";
import tagReducer, { TagReducer } from "./tag.reducer";
import emailReducer from "./email.reducer";

export interface StateType {
    frontProfile: FrontTypeReducer;
    offer: OfferTypeReducer;
    feedback: FeedbackReducerType;
    category: CategoryReducer;
    menu: ListingMenuReducer;
    tags: TagReducer;
    detailProduct: DetailProductReducer;
    socialMedia: SocialTypeReducer;
    profile: ProfileFrontTypeReducer;
}
const rootReducer = combineReducers({
    frontProfile: FrontProfileReducer,
    offer: OfferReducer,
    feedback: feedbackReducer,
    email: emailReducer,
    category: categoryReducer,
    menu: listingMenuReducer,
    tags: tagReducer,
    detailProduct: detailProductReducer,
    socialMedia: socialMediaReducer,
    profile: ProfileReducer,
});
export default rootReducer;
