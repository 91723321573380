import React, { useState, useEffect } from "react";
import { AiFillFire, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GeneralTitle from "../../Components/GeneralTitle";
import Loader from "../../Components/Loader";
import { PRODCUCTIMAGE_BASE_URL } from "../../Constants";
import { displayLoader } from "../../Store/action/loader.action";
import { getAllCategories } from "../../Store/api/category.api";
import { getAllDetailProduct } from "../../Store/api/detailProduct.api";
import { StateType } from "../../Store/reducer/index.reducer";
import { ToppingType } from "../../Store/type/detailProduct.type";
import { day } from "../Category";

const DetailProduct = ({ subDomain }: any) => {
    const dispatch = useDispatch<any>();
    const { id, currentVenue }: any = useParams();
    subDomain && GeneralTitle(subDomain);

    const navigate = useNavigate();
    const singleMenuDetails = useSelector(
        (state: StateType) => state.detailProduct?.detailProduct
    );
    const curr = useSelector(
        (state: StateType) => state.category?.categoryRes?.currency
    );
    const currencyArr = curr?.split("-");
    const loader = useSelector(
        (state: StateType) => state.detailProduct?.loader
    );
    const [quantity, setQuantity] = useState(1);
    const [toppings, setToppings] = useState<Array<string>>([]);

    const goBack = () => {
        navigate(-1);
    };
    const date = new Date();
    const device_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    useEffect(() => {
        id && id.length !== "" && dispatch(getAllDetailProduct(id));
        dispatch(displayLoader(true));
        subDomain &&
            dispatch(
                getAllCategories(
                    subDomain,
                    device_time,
                    { navigate: navigate },
                    day
                )
            );
    }, [dispatch, id, subDomain]);
    const updateQuantity = (qty: number) => {
        setQuantity((prev) => prev + qty);
    };
    const handleSelect = (toppingitem: ToppingType) => {
        toppings.includes(toppingitem.id)
            ? setToppings((prev) =>
                  prev.filter((item) => item !== toppingitem.id)
              )
            : setToppings((prev) => [...prev, toppingitem.id]);
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    const checkTopping = (data: any) => {
        let toppingId = toppings?.find((x: any) => x === data.id);
        if (toppingId !== undefined) {
            return true;
        }
        return false;
    };
    return (
        <>
            <div className="whole_wrapper">
                {loader ? (
                    <Loader classType="loading-spinner" />
                ) : (
                    <div className="detailmenu_wrapper">
                        {singleMenuDetails?.photo && (
                            <div className="detailmenu__image">
                                <img
                                    src={`${PRODCUCTIMAGE_BASE_URL}/${singleMenuDetails?.photo}`}
                                />
                            </div>
                        )}
                        <div className="detailmenu__arrow" onClick={goBack}>
                            <BsArrowLeftShort className="arrow-left" />
                        </div>
                        <div
                            className={
                                singleMenuDetails?.photo
                                    ? "detailitem_container"
                                    : "detailitem_container inactive"
                            }
                        >
                            <div className="detailitem__content">
                                <div className="detail_popular">
                                    {singleMenuDetails?.is_popular == 1 && (
                                        <div className="menu_status">
                                            <span>Popular</span>
                                        </div>
                                    )}
                                    {singleMenuDetails?.is_hot == 1 && (
                                        <div className="fire">
                                            <AiFillFire className="fire-icon" />
                                        </div>
                                    )}
                                </div>
                                <h4>{singleMenuDetails?.name}</h4>
                                <p className="detail__desc">
                                    {singleMenuDetails?.description}
                                </p>
                                <div className="detail_price_GVD">
                                    <p className="detail__price">
                                        {currencyArr?.length && currencyArr[0]}
                                        &nbsp;
                                        {singleMenuDetails?.mrp}
                                    </p>

                                    <div className="menu__GVD">
                                        {singleMenuDetails?.tags?.map(
                                            (tag: any, index: number) => {
                                                return (
                                                    <>
                                                        <span key={index}>
                                                            {tag}
                                                        </span>
                                                    </>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="item__toppings">
                                {Array.isArray(singleMenuDetails?.topping) &&
                                    singleMenuDetails?.topping?.map(
                                        (data: any, index: number) => {
                                            return (
                                                <>
                                                    <div
                                                        className="topping_title"
                                                        key={index}
                                                    >
                                                        {data.name}
                                                    </div>
                                                    {data?.topping?.map(
                                                        (
                                                            datatopping: any,
                                                            i: number
                                                        ) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        className="itemtopping__content"
                                                                        key={i}
                                                                        onClick={() => {
                                                                            handleSelect(
                                                                                datatopping
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="topping"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {/* {text_truncate(
                                                                        {
                                                                            str: datatopping.name,
                                                                            length: 25,
                                                                            ending: null,
                                                                        }
                                                                    )} */}
                                                                                {
                                                                                    datatopping.name
                                                                                }
                                                                            </span>

                                                                            <span>
                                                                                +
                                                                                {
                                                                                    datatopping.mrp
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        {/* <label className="detail__checkbox">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={checkTopping(
                                                                                    datatopping
                                                                                )}
                                                                            />
                                                                            <div
                                                                                className="checkmark"
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    e.stopPropagation()
                                                                                }
                                                                            ></div>
                                                                        </label> */}
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            );
                                        }
                                    )}
                            </div>
                            <div className="item_toppings">
                                <div className="item__toppings">
                                    {Array.isArray(
                                        singleMenuDetails?.topping
                                    ) &&
                                        singleMenuDetails?.upsell?.map(
                                            (data: any, index: number) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="topping_title"
                                                            key={index}
                                                        >
                                                            {data.name}
                                                        </div>
                                                        {data?.upsell?.map(
                                                            (
                                                                dataupsell: any,
                                                                i: number
                                                            ) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className="itemtopping__content"
                                                                            key={
                                                                                i
                                                                            }
                                                                            onClick={() => {
                                                                                handleSelect(
                                                                                    dataupsell
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="topping"
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        dataupsell.name
                                                                                    }
                                                                                </span>

                                                                                <span>
                                                                                    +
                                                                                    {
                                                                                        dataupsell.mrp
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                );
                                            }
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DetailProduct;
