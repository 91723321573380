import { GET_BTNLOADER, GET_LOADER } from "../const";

export const displayLoader = (data: any) => {
    return {
        type: GET_LOADER,
        payload: data,
    };
};
export const displayBtnLoader = (data: any) => {
    return {
        type: GET_BTNLOADER,
        payload: data,
    };
};
