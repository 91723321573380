import { FEEDBACK_ACTION } from "../const";
import { FeedbackType } from "../type/Feedback.type";

export interface FeedbackReducerType {
    feedback: FeedbackType;
    loader: boolean;
}
const initialState: FeedbackReducerType = {
    feedback: {} as FeedbackType,
    loader: true,
};
const feedbackReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case FEEDBACK_ACTION:
            return {
                ...state,
                feedback: payload,
                loader: false,
            };
        default:
            return state;
    }
};
export default feedbackReducer;
