import { Dispatch } from "redux";
import { $FIXME } from "../../Constants";
import api from "./index.api";
import { getSocialCount } from "../action/socialCount.action";

export const getCountApi = (id: number) => (dispatch: Dispatch) => {
    api.post(`/tools-add-click/${id}`)
        .then((response: any) => {
            dispatch(getSocialCount(response));
        })
        .catch((err) => {});
};
