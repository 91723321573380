import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "../../Components/Button";
import Footer from "../../Components/Footer";
import GeneralTitle from "../../Components/GeneralTitle";
import Navbar from "../../Components/Navbar";
import { getSocialMediaApi } from "../../Store/api/socialMedia.api";
import { StateType } from "../../Store/reducer/index.reducer";

const SocialMedia = ({ subDomain }: any) => {
    const dispatch: any = useDispatch();
    const socialData = useSelector(
        (state: StateType) => state.socialMedia?.socialMedia
    );
    subDomain && GeneralTitle(subDomain);
    useEffect(() => {
        dispatch(getSocialMediaApi(subDomain));
    }, [subDomain]);
    return (
        <>
            <Navbar showArrow showVenue={subDomain} />
            <div className="whole_wrapper">
                <div className="socialmedia_container container">
                    <div className="social_button">
                        {socialData?.map((sData: any, i: number) => {
                            return (
                                <a
                                    href={sData.link}
                                    target="_blank"
                                    className={
                                        sData?.name == "Facebook"
                                            ? "fb_media"
                                            : sData?.name == "Twitter"
                                            ? "tw_media"
                                            : sData?.name == "Instagram"
                                            ? "ig_media"
                                            : "all_media"
                                    }
                                >
                                    {sData.name && (
                                        <button key={sData?.id}>
                                            {sData?.name}
                                        </button>
                                    )}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default SocialMedia;
