import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { countryList } from "../../Helpers/CountryList";

type PropsType = {
    mode?: any;
    handleChange?: any;
    value?: string;
};

const Country = (props: PropsType) => {
    const { mode, handleChange, value } = props;
    return (
        <>
            <select
                placeholder="Select "
                className="select_input"
                onChange={(e) => handleChange(e, mode)}
                value={value}
            >
                {/* <option
                    value={mode == "country_num" ? "+95" : ""}
                    disabled
                    selected
                    hidden
                >
                    {mode == "country_name" ? "- Select your country -" : "+95"}
                </option> */}
                {/* {mode == "country_num" && (
                    <option value="+95" disabled selected hidden>
                        +95
                    </option>
                )} */}
                {countryList?.map((data: any, i: number) => {
                    return (
                        <>
                            <option
                                key={data?.dialling_code}
                                value={
                                    mode == "country_name"
                                        ? data?.country_name
                                        : data?.dialling_code
                                }
                            >
                                {mode == "country_name"
                                    ? data?.country_name
                                    : data?.dialling_code}
                            </option>
                        </>
                    );
                })}
            </select>
        </>
    );
};

export default Country;
