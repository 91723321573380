import { EMAIL_ACTION } from "../const";

const initialState = {
    email: {},
    btnloader: true,
};
const emailReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case EMAIL_ACTION:
            return {
                ...state,
                email: payload,
                btnloader: false,
            };
        default:
            return state;
    }
};
export default emailReducer;
