import { GET_ALL_CATEGORY, GET_LOADER } from "../const";
import { CategoryResType, CategoryType } from "../type/category.type";

export interface CategoryReducer {
    category: Array<CategoryType>;
    categoryRes: CategoryResType;
    loader: boolean;
}
const inititalState: CategoryReducer = {
    category: [],
    categoryRes: {} as CategoryResType,
    loader: true,
};

const categoryReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case GET_ALL_CATEGORY:
            return {
                ...state,
                category: payload.categories,
                categoryRes: { ...payload, currency: payload.currency ?? "$" },
            };
        case GET_LOADER:
            return {
                ...state,
                loader: payload,
            };

        default:
            return state;
    }
};

export default categoryReducer;
