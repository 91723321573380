import { $FIXME } from "../../Constants";
import { GET_ALLOFFER, GET_LOADER } from "../const";
import { OfferType } from "../type/Offer.type";
export interface OfferTypeReducer {
    offer: Array<OfferType>;
    loader: boolean;
}
const initialState: OfferTypeReducer = {
    offer: [],
    loader: true,
};
export const OfferReducer = (state = initialState, action: $FIXME) => {
    switch (action.type) {
        case GET_ALLOFFER:
            return {
                ...state,
                offer: action.payload,
            };
        case GET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        default:
            return state;
    }
};
