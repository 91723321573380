import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/styles/main.scss";
import Front from "./Pages/Front";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./Store/store";
import Feedback from "./Pages/Feedback";
import Offer from "./Pages/Offer";
import Category from "./Pages/Category";
import ListingMenu from "./Pages/ListingMenu";
import DetailProduct from "./Pages/DetailProduct";
import "antd/dist/reset.css";
import "./App.css";
import SocialMedia from "./Pages/SocialMedia";
import Profile from "./Pages/Profile";
import ContentNotFound from "./Components/ContentNotFound";
import NotSubscribed from "./Components/NotSubscribed";
import { url } from "./Constants";
import Generic from "./Pages/Generic";
import EmailForm from "./Components/EmailForm";

function App() {
    const subDomain = url().subdomain;
    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        {/* <Route
                            path="/"
                            element={<Front subDomain={subDomain} />}
                        /> */}
                        <Route
                            path="/welcome"
                            element={<Profile subDomain={subDomain} />}
                        />
                        <Route
                            path="/restaurant-not-subscribed"
                            element={<NotSubscribed />}
                        />
                        <Route
                            path="/category"
                            element={<Category subDomain={subDomain} />}
                        ></Route>
                        <Route
                            path="/offer"
                            element={<Offer subDomain={subDomain} />}
                        ></Route>
                        <Route
                            path="/feedback"
                            element={<Feedback subDomain={subDomain} />}
                        />
                        <Route
                            path="/contact"
                            element={<EmailForm subDomain={subDomain} />}
                        />
                        <Route
                            path="/social-media"
                            element={<SocialMedia subDomain={subDomain} />}
                        />

                        <Route
                            path="/"
                            element={<Generic subDomain={subDomain} />}
                        />
                        <Route
                            path="/listingmenu/:id/:restaurantid"
                            element={<ListingMenu subDomain={subDomain} />}
                        ></Route>
                        <Route
                            path="/detailmenu/:id"
                            element={<DetailProduct subDomain={subDomain} />}
                        ></Route>
                        <Route
                            path="/contentnotfound"
                            element={<ContentNotFound />}
                        ></Route>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;
