import React from "react";
type CardProps = {
    mainContent?: string;
    subText?: string;
    validDate?: string;
    image?: string;
    href?: string;
    head?: string;
    cardType?: "normal" | "reverse" | "noimage";
};

const Card = (props: CardProps) => {
    const { mainContent, image, href, validDate, cardType, head } = props;

    return (
        <>
            <a className="card-1" href={href}>
                <h4>{head}</h4>
                {/* <div className={`card-1__about card-1__about__${cardType}`}>
                    <div className="card-1__about__maincontent">
                        {mainContent}
                    </div>
                    <div className="card-1__about__subtext">
                        Vaild until {validDate}
                    </div>
                </div> */}
                <div className="card-1__image card-1__image__${cardType}">
                    <img src={image} alt="offer-category-image" />
                </div>
            </a>
        </>
    );
};
export default Card;
