import React from "react";
import { $FIXME } from "../../Constants";

type ButtonProps = {
    className?: $FIXME;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    htmlType?: "submit" | "button" | "reset";
    btnType?: "primary" | "secondary" | "default";
    loading?: boolean;
    size?: "small" | "middle" | "large";
    margin?: $FIXME;
    shape?: "circle" | "circle-outline" | "round";
    icon?: $FIXME;
    background?: string;
    fullWidth?: boolean;
    children?: $FIXME;
};
const Button = (props: ButtonProps) => {
    const { children, onClick, disabled, htmlType, btnType } = props;
    return (
        <>
            <button
                className={disabled ? "btn-disabled" : `btn btn-${btnType}`}
                onClick={onClick}
                type={htmlType}
                disabled={disabled}
            >
                {children}
            </button>
        </>
    );
};

export default Button;
