import { Dispatch } from "redux";
import { getTag } from "../action/tag.action";
import api from "./index.api";

export const getAllTags = (id: any) => (dispatch: Dispatch) => {
    api.get(`/tags/${id}`)
        .then((response) => {
            dispatch(getTag(response.data));
        })
        .catch((err) => {});
};
