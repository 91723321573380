import React, { useState, useEffect } from "react";
import {
    AiFillLinkedin,
    AiFillYoutube,
    AiOutlineTwitter,
} from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { FaInstagram, FaTripadvisor, FaWhatsapp, FaYelp } from "react-icons/fa";
import { Drawer } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { converTime } from "../Category";
import { getCountApi } from "../../Store/api/socialCount.api";
import { Collapse, Divider } from "antd";
import { GrShare } from "react-icons/gr";
import ReactPlayer from "react-player/lazy";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";

import Loader from "../../Components/Loader";
import { Skeleton } from "antd";
import GeneralTitle from "../../Components/GeneralTitle";
import { StateType } from "../../Store/reducer/index.reducer";
import { getProfileApi } from "../../Store/api/profile.api";
import { HomeIMAGE_BASE_URL } from "../../Constants";
import { ProfilePositionsType } from "../../Store/type/profile.type";
import DrawerComp from "../../Components/DrawerComp";
import { AboutUs, Address } from "../../Components/AboutUs";
import EmailForm from "../../Components/EmailForm";
import { formatTime } from "../Generic";

const Profile = ({ subDomain }: any) => {
    const [activeDrawer, setActiveDrawer] = useState<{
        isOpen?: boolean;
        type?: "contact" | "operating" | "address" | "about" | "";
    }>({ isOpen: false, type: "" });
    let navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { Panel } = Collapse;
    subDomain && GeneralTitle(subDomain);

    const profileData = useSelector(
        (state: StateType) => state.profile?.profile
    );
    const { loader } = useSelector((state: StateType) => state.frontProfile);

    const theme = profileData?.profile?.theme;
    // useEffect(() => {
    //     if (subDomain) {
    //         dispatch(getFrontApi(subDomain, { navigate: navigate }));
    //         window.scrollTo({
    //             top: 0,
    //             left: 0,
    //         });
    //     }
    // }, [subDomain, dispatch]);

    useEffect(() => {
        // if (!firstrender.current) {
        subDomain && dispatch(getProfileApi(subDomain, { navigate: navigate }));
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, [subDomain, dispatch]);
    const handleClick = (id: number) => {
        dispatch(getCountApi(id));
    };

    const showDrawer = (
        isOpen?: boolean,
        type?: "about" | "operating" | "contact" | "address"
    ) => {
        setActiveDrawer({ type: type ?? activeDrawer?.type, isOpen });
    };
    const dynfontColor =
        profileData?.profile?.theme?.background ==
            "linear-gradient(180deg, #fbd3e9 0%, #bb377d 100%)" ||
        profileData?.profile?.theme?.background ==
            "linear-gradient(180deg, #de6262 0%, #ffb88c 100%)"
            ? "#ffffff"
            : profileData?.profile?.theme?.background == "#ffffff"
            ? "#000000"
            : profileData?.profile?.theme?.font_color;
    const btnStyle = {
        color: profileData?.profile?.theme?.font_color,
        border:
            profileData?.profile?.theme?.button_color == "#ffffff" &&
            profileData?.profile?.theme?.background == "#ffffff"
                ? "1px solid #000000"
                : "none",

        background: profileData?.profile?.theme?.button_color,
    };
    const font_color = {
        color: profileData?.profile?.theme?.font_color,
    };
    const bgStyle = {
        background: profileData?.profile?.theme?.background,
    };

    const btnSvg = (
        <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // className="hovered-content"
        >
            <path
                d="M9.125 8.1875H4.25M5.875 11.4375H4.25M10.75 4.9375H4.25M14 4.775V13.225C14 14.5901 14 15.2727 13.7343 15.7941C13.5006 16.2527 13.1277 16.6256 12.6691 16.8593C12.1477 17.125 11.4651 17.125 10.1 17.125H4.9C3.53487 17.125 2.85231 17.125 2.3309 16.8593C1.87225 16.6256 1.49936 16.2527 1.26567 15.7941C1 15.2727 1 14.5901 1 13.225V4.775C1 3.40987 1 2.72731 1.26567 2.2059C1.49936 1.74725 1.87225 1.37436 2.3309 1.14067C2.85231 0.875 3.53487 0.875 4.9 0.875H10.1C11.4651 0.875 12.1477 0.875 12.6691 1.14067C13.1277 1.37436 13.5006 1.74725 13.7343 2.2059C14 2.72731 14 3.40987 14 4.775Z"
                stroke={profileData?.profile?.theme?.font_color}
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );

    return (
        <>
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="vm_wrapper nvm_wrapper" style={bgStyle}>
                    <div className="profilesection">
                        <div className="image_innercontent">
                            <img
                                src={
                                    profileData?.profile?.banner ==
                                    "/images/user/"
                                        ? "/Images/c-img.png"
                                        : `${HomeIMAGE_BASE_URL}/${profileData?.profile?.banner}`
                                }
                                className="cover-img"
                                alt=" sociallink_image"
                            />

                            <div className="profile_content">
                                <div className="ld_logo">
                                    <img
                                        src={
                                            profileData?.profile?.logo ==
                                            "/images/user/"
                                                ? "../Images/logoIII.png"
                                                : `${HomeIMAGE_BASE_URL}/${profileData?.profile?.logo}`
                                        }
                                        alt=" sociallink_image"
                                    />
                                </div>
                                <div
                                    className="ld_title"
                                    style={{
                                        color: dynfontColor,
                                    }}
                                >
                                    {profileData?.profile?.name}
                                </div>
                                {profileData?.profile?.bio?.length && (
                                    <p
                                        className="bio-data"
                                        style={{
                                            color: dynfontColor,
                                        }}
                                    >
                                        {profileData?.profile?.bio}
                                    </p>
                                )}
                                {profileData?.positions?.map(
                                    (data: ProfilePositionsType, i: number) => {
                                        return (
                                            <>
                                                {data.type == "operation" && (
                                                    <div
                                                        key={i}
                                                        className="view-ophour"
                                                        onClick={() =>
                                                            showDrawer(
                                                                true,
                                                                "operating"
                                                            )
                                                        }
                                                        style={{
                                                            color: dynfontColor,
                                                        }}
                                                    >
                                                        View Operating hour
                                                    </div>
                                                )}
                                            </>
                                        );
                                    }
                                )}

                                <div className="ld_socialicons">
                                    {!(
                                        profileData?.socialGroup?.facebook
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.facebook
                                            }
                                            target="blank"
                                        >
                                            <BsFacebook
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.instagram
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.instagram
                                            }
                                            target="blank"
                                        >
                                            <FaInstagram
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.twitter
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.twitter
                                            }
                                            target="blank"
                                        >
                                            <AiOutlineTwitter
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.linkedin
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.linkedin
                                            }
                                            target="blank"
                                        >
                                            <AiFillLinkedin
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.youtube
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.youtube
                                            }
                                            target="blank"
                                        >
                                            <AiFillYoutube
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.yelp
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup?.yelp
                                            }
                                            target="blank"
                                        >
                                            <FaYelp
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.whatsapp
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.whatsapp
                                            }
                                            target="blank"
                                        >
                                            <FaWhatsapp
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                    {!(
                                        profileData?.socialGroup?.tripadvisor
                                            ?.length == 0
                                    ) && (
                                        <a
                                            className={`${theme}`}
                                            href={
                                                profileData?.socialGroup
                                                    ?.tripadvisor
                                            }
                                            target="blank"
                                        >
                                            <FaTripadvisor
                                                size={24}
                                                color={dynfontColor}
                                            />
                                        </a>
                                    )}
                                </div>

                                <div className="updated-contentWrapper">
                                    {profileData?.positions?.map(
                                        (
                                            data: ProfilePositionsType,
                                            i: number
                                        ) => {
                                            return (
                                                <>
                                                    {data?.type == "header" &&
                                                        data.heading !=
                                                            null && (
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    fontWeight: 600,
                                                                    color: dynfontColor,
                                                                }}
                                                            >
                                                                {
                                                                    data
                                                                        ?.heading
                                                                        ?.title
                                                                }
                                                            </div>
                                                        )}
                                                    {data?.social_link && (
                                                        <div
                                                            className="updated-content"
                                                            key={i}
                                                        >
                                                            <div className="ldcontent-btnlist">
                                                                {data?.type ==
                                                                    "social" && (
                                                                    <a
                                                                        href={
                                                                            data
                                                                                ?.social_link
                                                                                ?.link
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        <button
                                                                            className="tools-btn"
                                                                            onClick={() =>
                                                                                handleClick(
                                                                                    data
                                                                                        ?.social_link
                                                                                        ?.id
                                                                                )
                                                                            }
                                                                            style={
                                                                                btnStyle
                                                                            }
                                                                        >
                                                                            {data
                                                                                ?.social_link
                                                                                ?.image && (
                                                                                <img
                                                                                    className="updated-socialicon"
                                                                                    src={`${data?.social_link?.image}`}
                                                                                />
                                                                            )}
                                                                            {data
                                                                                ?.social_link
                                                                                ?.name && (
                                                                                <div
                                                                                    style={
                                                                                        font_color
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        data
                                                                                            ?.social_link
                                                                                            ?.name
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </button>
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data.type === "pdf" &&
                                                        data?.pdf_menu !=
                                                            null && (
                                                            <div className="ldcontent-btnlist">
                                                                <a
                                                                    href={`https://docs.google.com/gview?url=${HomeIMAGE_BASE_URL}${data?.pdf_menu?.file_path}`}
                                                                    target="_blank"
                                                                >
                                                                    <button
                                                                        className="pdf-btn tools-btn"
                                                                        style={
                                                                            btnStyle
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                font_color
                                                                            }
                                                                        >
                                                                            {
                                                                                data
                                                                                    ?.pdf_menu
                                                                                    ?.title
                                                                            }
                                                                        </div>
                                                                        <GrShare className="grshare" />
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        )}
                                                    {data.type == "video" &&
                                                        data.video != null && (
                                                            <div className="md-image">
                                                                <ReactPlayer
                                                                    url={
                                                                        data
                                                                            ?.video
                                                                            ?.video_path
                                                                    }
                                                                    className="react-player"
                                                                    width={
                                                                        "100%"
                                                                    }
                                                                    height={181}
                                                                />
                                                            </div>
                                                        )}
                                                    {data.type == "about" && (
                                                        <div className="ldcontent-btnlist ">
                                                            <button
                                                                style={btnStyle}
                                                                className="tools-btn"
                                                                onClick={() =>
                                                                    showDrawer(
                                                                        true,
                                                                        "about"
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    style={
                                                                        font_color
                                                                    }
                                                                >
                                                                    About Us
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {data.type == "address" && (
                                                        <div className="ldcontent-btnlist ">
                                                            <button
                                                                style={btnStyle}
                                                                className="tools-btn"
                                                                onClick={() =>
                                                                    showDrawer(
                                                                        true,
                                                                        "address"
                                                                    )
                                                                }
                                                            >
                                                                <div
                                                                    style={
                                                                        font_color
                                                                    }
                                                                >
                                                                    Address
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {data.type == "contact" && (
                                                        <div className="ldcontent-btnlist ">
                                                            <button
                                                                style={btnStyle}
                                                                onClick={() =>
                                                                    showDrawer(
                                                                        true,
                                                                        "contact"
                                                                    )
                                                                }
                                                                className="tools-btn"
                                                            >
                                                                <div
                                                                    style={
                                                                        font_color
                                                                    }
                                                                >
                                                                    Contact Us
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {data.type == "builder" && (
                                                        <div className="ldcontent-btnlist ">
                                                            <Link
                                                                to={`/category`}
                                                                className="link_content"
                                                            >
                                                                <button
                                                                    className="tools-btn"
                                                                    style={
                                                                        btnStyle
                                                                    }
                                                                >
                                                                    {btnSvg}

                                                                    <div
                                                                        style={
                                                                            font_color
                                                                        }
                                                                    >
                                                                        View
                                                                        menu
                                                                    </div>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {data.type == "review" && (
                                                        <div className="ldcontent-btnlist ">
                                                            <Link
                                                                to={`/feedback`}
                                                                className="link_content"
                                                            >
                                                                <button
                                                                    className="tools-btn"
                                                                    style={
                                                                        btnStyle
                                                                    }
                                                                >
                                                                    {btnSvg}

                                                                    <div
                                                                        style={
                                                                            font_color
                                                                        }
                                                                    >
                                                                        Feedback
                                                                    </div>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        {profileData?.profile?.theme?.show_logo == 1 && (
                            <div className="footer">
                                <div className="powered_by">
                                    <p
                                        style={{
                                            color:
                                                profileData.profile.theme
                                                    .background == "#ffffff"
                                                    ? "#000000"
                                                    : "#ffffff",
                                        }}
                                    >
                                        Powered by
                                    </p>
                                    {profileData.profile.theme.background ==
                                    "#ffffff" ? (
                                        <img
                                            src="/Images/logo_myordr.svg"
                                            alt="ordr-logo"
                                        />
                                    ) : (
                                        <img
                                            src="/Images/vlogo.svg"
                                            alt="ordr-logo"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <DrawerComp
                        open={activeDrawer?.isOpen}
                        title={
                            activeDrawer?.type == "about"
                                ? "About Us"
                                : activeDrawer?.type == "operating"
                                ? "Operating Hours :"
                                : activeDrawer?.type == "contact"
                                ? "Send Email"
                                : activeDrawer?.type == "address"
                                ? " Address"
                                : "About Us"
                        }
                        placement="bottom"
                        className={
                            activeDrawer?.type == "operating"
                                ? "operating_hour"
                                : activeDrawer?.type == "contact"
                                ? "general-drawer contact-drawer"
                                : activeDrawer?.type == "address"
                                ? "general-drawer address-drawer"
                                : activeDrawer?.type == "about"
                                ? "general-drawer aboutus-drawer"
                                : "general-drawer"
                        }
                        onClose={() => showDrawer(false)}
                    >
                        {activeDrawer?.type == "about" &&
                            Array?.isArray(profileData?.positions) &&
                            profileData?.positions?.map(
                                (data: any, i: number) => {
                                    return (
                                        <>
                                            {data?.about_us && (
                                                <AboutUs
                                                    key={i}
                                                    image={
                                                        data?.about_us?.image
                                                    }
                                                    content={
                                                        data?.about_us?.content
                                                    }
                                                />
                                            )}
                                        </>
                                    );
                                }
                            )}
                        {activeDrawer?.type == "address" &&
                            Array?.isArray(profileData?.positions) &&
                            profileData?.positions?.map(
                                (data: any, i: number) => {
                                    return (
                                        <>
                                            {data?.address && (
                                                <Address
                                                    key={i}
                                                    iFrameSrc={
                                                        data?.address?.link
                                                    }
                                                    locationAdd={
                                                        data?.address?.address
                                                    }
                                                    location={
                                                        data?.address?.address
                                                    }
                                                    phoneNum={
                                                        data?.address?.phone
                                                    }
                                                    email={data?.address?.email}
                                                />
                                            )}
                                        </>
                                    );
                                }
                            )}
                        {activeDrawer?.type == "contact" && (
                            <EmailForm
                                subDomain={subDomain}
                                drawerClose={() => showDrawer(false)}
                            />
                        )}
                        {activeDrawer?.type == "operating" &&
                            Array?.isArray(profileData?.operations) &&
                            profileData?.operations?.map(
                                (data: any, i: number) => {
                                    return (
                                        <>
                                            <div
                                                className="weekday-col"
                                                key={i}
                                            >
                                                <span>{data?.day}</span>
                                                <span>
                                                    {`${formatTime(
                                                        data?.start_time
                                                    )} - ${formatTime(
                                                        data?.end_time
                                                    )}`}
                                                </span>
                                            </div>
                                        </>
                                    );
                                }
                            )}
                    </DrawerComp>
                </div>
            )}
        </>
    );
};

export default Profile;
