import { notification } from "antd";
import { Dispatch } from "redux";
import api from "./index.api";
import { postEmail } from "../action/emailform.action";
import { displayBtnLoader, displayLoader } from "../action/loader.action";

export interface EmailBodyType {
    name?: string;
    email?: string;
    message?: string;
    slug?: string;
}
export const postEmailApi =
    (
        data: any,
        props: {
            drawerClose: any;
        }
    ) =>
    async (dispatch: Dispatch) => {
        const { drawerClose } = props;
        try {
            try {
                const response = await api.post(`/tools-contact-mail`, data);
                const key = "updatable";
                // dispatch(displayBtnLoader());
                dispatch(postEmail(response));
                drawerClose();
                notification.open({
                    key,
                    duration: 1,
                    message: "Email successfully sent",
                });
                return await Promise.resolve(true);
            } catch (err) {
                return await Promise.resolve(false);
            }
        } finally {
            return dispatch(displayBtnLoader(false));
        }
    };
