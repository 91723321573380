export const PROFILEACTION = {
    GET_ALLFrontData: "GET_ALLFrontData",
};
export const GET_ALLPROFILE = "GET_ALLPROFILE";
export const GET_SOCIALCOUNT = "GET_SOCIALCOUNT";

//offer
export const GET_ALLOFFER = "GET_ALLOFFER";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const FEEDBACK_ACTION = "FEEDBACK_ACTION";
export const EMAIL_ACTION = "EMAIL_ACTION";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_MENU = "GET_ALL_MENU";
export const GET_LOADER = "Get_LOADER";
export const GET_BTNLOADER = "Get_BTNLOADER";

export const GET_TAG = "GET_TAG";
export const GET_DETAILPRODUCT = "GET_DETAILPRODUCT";
export const GET_SOCIALMEDIA = "GET_SOCIALMEDIA";
