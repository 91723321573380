import React, { useEffect, useState } from "react";
import Card from "../../Components/Card";
import Navbar from "../../Components/Navbar";
import CardList from "../../Helpers/CardList";
import { StateType } from "../../Store/reducer/index.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOfferApi } from "../../Store/api/offer.api";
import { OffIMAGE_BASE_URL } from "../../Constants";
import Loader from "../../Components/Loader";
import Footer from "../../Components/Footer";
import GeneralTitle from "../../Components/GeneralTitle";

const date = new Date();
export const device_time =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

const Offer = (subDomain: any) => {
    const offerData = useSelector((state: StateType) => state.offer);
    const loader = useSelector((state: StateType) => state.offer?.loader);
    const dispatch: any = useDispatch();
    useEffect(() => {
        subDomain && dispatch(getOfferApi(subDomain.subDomain));
    }, [subDomain.subDomain]);
    return (
        <>
            <Navbar showArrow title="Offers" />
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="whole_wrapper">
                    <div className="offer container">
                        <div className="offer__title">
                            <h1>Offers</h1>
                            <p className="subcontent">
                                From
                                <span>
                                    <strong> {subDomain.subDomain}</strong>
                                </span>
                            </p>
                        </div>
                        <div className="offer__cardwrapper">
                            {offerData?.offer?.map(
                                (carddata: any, index: number) => {
                                    return (
                                        <Card
                                            key={index}
                                            // cardType={index % 2 == 0 ? "normal" : "reverse"}
                                            cardType={
                                                carddata.image
                                                    ? "normal"
                                                    : "noimage"
                                            }
                                            mainContent={carddata.mainContent}
                                            validDate={carddata.validDate}
                                            image={`${OffIMAGE_BASE_URL}/${carddata.image}`}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};
export default Offer;
