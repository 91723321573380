import { Dispatch } from "redux";
import { getCategory } from "../action/category.action";
import { displayLoader } from "../action/loader.action";
import api from "./index.api";

export const getAllCategories =
    (slug: any, device_time: any, navigate: any, day: any) =>
    (dispatch: Dispatch) => {
        // const { navigate } = props;
        api.get(
            `/category/venue/${slug}/?device_time=${device_time}&day=${day}`
        )
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(
                    getCategory({
                        ...response.data?.data[0],
                        currency: response.data?.currency,
                    })
                );
            })
            .catch((err) => {
                if (err.response.status == 403) {
                    navigate(`/restaurant-not-subscribed`, {
                        replace: true,
                    });
                }
                dispatch(displayLoader(false));
            });
    };
// const getCurrencySymbol = (currency: string) => {
//     switch (currency) {
//         case "yuan":
//             return "CNY";
//         case "ringgit":
//             return "MYR";
//         case "thai-baht":
//             return "THB";
//         case "myanmar-kyat":
//             return "MMK";
//         default:
//             return "$";
//     }
// };
