import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Country from "./Country";
import { postEmailApi } from "../../Store/api/email.api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GeneralTitle from "../GeneralTitle";
import { StateType } from "../../Store/reducer/index.reducer";
import Loader from "../Loader";
import { Spin } from "antd";
import {
    displayBtnLoader,
    displayLoader,
} from "../../Store/action/loader.action";
const EmailForm = ({ subDomain, drawerClose }: any) => {
    const [phone, setPhone] = useState("");
    const [userData, setUserData] = useState<{
        name?: string;
        email: string;
        message?: string;
        phone?: string;
        countrycode?: string;
    }>({ name: "", email: "", message: "", phone: "", countrycode: "+95" });
    const [error, setError] = useState(false);
    const dispatch = useDispatch<any>();
    let navigate = useNavigate();
    subDomain && GeneralTitle(subDomain);
    const btnloader = useSelector(
        (state: StateType) => state.frontProfile.btnLoader
    );

    const handleChange = (e: any, mode?: any) => {
        let { name, value } = e.target;
        if (mode == "country_num") {
            setUserData((prev) => ({
                ...prev,
                countrycode: value,
            }));
        } else {
            setUserData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleSubmit = async (values: any) => {
        values.preventDefault();
        const toSend = {
            name: userData.name,
            email: userData.email,
            message: userData.message,
            phone: userData.phone,
            countrycode: userData.countrycode,
            slug: subDomain,
        };
        let data = toSend;
        if (
            userData.name?.length == 0 ||
            userData.email.length == 0 ||
            userData.message?.length == 0 ||
            userData.phone?.length == 0 ||
            userData.countrycode?.length == 0
        ) {
            setError(true);
        } else {
            setError(false);
            dispatch(displayBtnLoader(true));
            let success = await dispatch(postEmailApi(data, { drawerClose }));
            if (success)
                setUserData({
                    name: "",
                    email: "",
                    message: "",
                    phone: "",
                    countrycode: "+95",
                });
        }
    };
    return (
        <>
            <form className="email_form" onSubmit={handleSubmit}>
                {/* <h2 className="drawer-title">Send Email</h2> */}
                <div className="form_inputfield">
                    <input
                        type="text"
                        placeholder="Name*"
                        name="name"
                        onChange={handleChange}
                        value={userData?.name}
                    />
                    {error && !userData.name?.length ? (
                        <p className="errmsg">Name is required!</p>
                    ) : (
                        ""
                    )}
                </div>
                <div className="form_inputfield">
                    <input
                        type="text"
                        placeholder="Email Address*"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                    />
                    {error && !userData.email?.length ? (
                        <p className="errmsg">Email is required!!</p>
                    ) : (
                        ""
                    )}
                </div>

                <div className="form_inputfield">
                    <div className=" country_inputfield">
                        <div className="country_smselect">
                            <Country
                                mode="country_num"
                                handleChange={handleChange}
                                value={userData.countrycode}
                            />
                        </div>
                        <div className="related_inputfield">
                            <input
                                type="number"
                                placeholder="Mobile*"
                                name="phone"
                                value={userData.phone}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {error && !userData.phone?.length ? (
                        <p className="errmsg">Phone Number is required</p>
                    ) : (
                        ""
                    )}
                </div>
                <div className="form_inputfield">
                    <textarea
                        placeholder="Message*"
                        name="message"
                        value={userData.message}
                        onChange={handleChange}
                    />
                    {error && !userData.message?.length ? (
                        <p className="errmsg">Message is required</p>
                    ) : (
                        ""
                    )}
                </div>
                <p className="email-desc">
                    By submitting your contact details, you are providing your
                    data to{" "}
                    <span style={{ textTransform: "capitalize" }}>
                        {subDomain}
                    </span>{" "}
                    who may contact you.
                </p>
                <button className="email_submitbtn" type="submit">
                    {btnloader ? "Sending..." : "Send"}
                </button>
            </form>
        </>
    );
};

export default EmailForm;
