import { $FIXME } from "../../Constants";
import { GET_ALLPROFILE, GET_LOADER } from "../const";
import { ProfileFrontType, ProfileOfferFrontType } from "../type/profile.type";
export interface ProfileFrontTypeReducer {
    profile: ProfileFrontType;
    pOffer: Array<ProfileOfferFrontType>;
    loader: boolean;
}
const initialState: ProfileFrontTypeReducer = {
    profile: {} as ProfileFrontType,
    pOffer: [],
    loader: true,
};

const ProfileReducer = (state = initialState, action: $FIXME) => {
    switch (action.type) {
        case GET_ALLPROFILE:
            return {
                ...state,
                pOffer: action.payload,
                profile: action.payload,
            };
        case GET_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        default:
            return state;
    }
};
export default ProfileReducer;
