import { GET_LOADER, GET_MENU } from "../const";
import { MenuType } from "../type/listingMenu.type";

export interface ListingMenuReducer {
    menu: MenuType;
    loader: boolean;
}
const inititalState: ListingMenuReducer = {
    menu: {} as MenuType,
    loader: true,
};

const listingMenuReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case GET_MENU:
            return {
                ...state,
                menu: payload,
            };
        case GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default listingMenuReducer;
