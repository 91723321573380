import { Dispatch } from "redux";
import { $FIXME } from "../../Constants";
import { getFront } from "../action/front.action";
import { displayLoader } from "../action/loader.action";
import { getProfile } from "../action/profile.action";
import api from "./index.api";

export const getProfileApi =
    (slug: $FIXME, props: any) => (dispatch: Dispatch) => {
        const { navigate } = props;
        api.get(`/welcome-profile/${slug}`)
            .then((response: $FIXME) => {
                dispatch(displayLoader(false));
                dispatch(getProfile(response.data));
                // if (response.data.status == "error") {
                //     navigate(`/contentNotFound`, {
                //         replace: true,
                //     });
                // }
            })
            .catch((err: $FIXME) => {
                dispatch(displayLoader(false));
                if (err.response.status == 403) {
                    navigate(`/restaurant-not-subscribed`, {
                        replace: true,
                    });
                }
                if (err.response.status == 500) {
                    navigate(`/contentNotFound`, {
                        replace: true,
                    });
                }
            });
    };
