import { Dispatch } from "redux";
import { $FIXME } from "../../Constants";
import { displayLoader } from "../action/loader.action";
import { getSocialMedias } from "../action/socialMedia.action";
import api from "./index.api";

export const getSocialMediaApi = (slug: $FIXME) => (dispatch: Dispatch) => {
    api.get(`/social/${slug}`)
        .then((response: $FIXME) => {
            dispatch(displayLoader(false));
            dispatch(getSocialMedias(response.data?.data));
        })
        .catch((err: $FIXME) => {
            dispatch(displayLoader(false));
        });
};
