import React, { useState } from "react";
import { Button, Drawer } from "antd";

type DrawerProps = {
    title?: string;
    children?: any;
    open?: any;
    setOpen?: any;
    clickBtn?: string;
    onClick?: any;
    onClose?: any;
    placement?: any;
    className?: any;
};
const DrawerComp = (props: DrawerProps) => {
    const { title, children, onClose, open, placement, className } = props;
    return (
        <>
            <div className="drawer-container">
                <Drawer
                    title={title}
                    placement={placement}
                    onClose={onClose}
                    open={open}
                    className={className}
                >
                    {children}
                </Drawer>
            </div>
        </>
    );
};

export default DrawerComp;
