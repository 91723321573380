import { notification } from "antd";
import { Dispatch } from "redux";
import api from "./index.api";
import { getFeedback } from "../action/feedback.action";

export interface FeedbackBodyType {
    start?: number;
    feedback?: string;
    slug?: string;
}
export const getFeedbackApi =
    (data: any, props: { subDomain: string | undefined; navigate: any }) =>
    (dispatch: Dispatch) => {
        const { subDomain, navigate } = props;
        api.post(`/feedback`, data)
            .then((response: any) => {
                const key = "updatable";
                dispatch(getFeedback(response));
                subDomain &&
                    navigate(`/`, {
                        replace: true,
                    });
                notification.open({
                    key,
                    duration: 1,
                    message: "Feedback sent",
                });
            })
            .catch((err) => {});
    };
