import { Dispatch } from "redux";
import { getMenu } from "../action/listingMenu.action";
import { displayLoader } from "../action/loader.action";
import api from "./index.api";

interface Props {
    id: string;
    tag?: string | number;
    navigate?: any;
}
export const getAllMenu = (data: Props) => (dispatch: Dispatch) => {
    const { id, tag, navigate } = data;
    const query = tag ? `tag=${tag}` : "";

    dispatch(displayLoader(true));
    dispatch(getMenu([]));
    api.get(`/category/${id}/products?${query}`)
        .then((response) => {
            dispatch(displayLoader(false));
            dispatch(getMenu(response.data));
        })
        .catch((err) => {
            if (err.response.status == 403) {
                navigate(`/restaurant-not-subscribed`, {
                    replace: true,
                });
            }
            dispatch(displayLoader(false));
        });
};
