import React from "react";

const NotSubscribed = () => {
    const resStyle = {
        color: "#212121",
        padding: "20px",
        fontSize: "20px",
    };
    return <div style={resStyle}>Restaurant not subscribed</div>;
};

export default NotSubscribed;
