import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="powered_by">
                    <p>Powered by</p>
                    <img src="/Images/logo_myordr.svg " alt="logo " />
                </div>
                {/* <p className="welcomepage__desc">
                    Beautiful interactive menus for leading venues.
                </p>
                <Link to="/" className="learnmore">
                    Learn More
                </Link> */}
            </div>
        </>
    );
};

export default Footer;
