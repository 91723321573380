import { $FIXME } from "../../Constants";
import { GET_ALLPROFILE, PROFILEACTION } from "../const";
import { FrontType } from "../type/Front.type";

export const getProfile = (data: FrontType) => {
    return {
        type: GET_ALLPROFILE,
        payload: data,
    };
};
