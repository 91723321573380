export const FeedbackList = [
    {
        scr: "/Images/imgI.png",
        feedback: "Taste",
        val: "taste",
    },
    {
        scr: "/Images/imgII.png",
        feedback: "Price",
        val: "price",
    },
    {
        scr: "/Images/imgIII.png",
        feedback: "Food Quality",
        val: "food",
    },
    {
        scr: "/Images/imgIV.svg",
        feedback: "Service",
        val: "service",
    },
    {
        scr: "/Images/imgV.svg",
        feedback: "Speed of Service",
        val: "speed",
    },
    {
        scr: "/Images/imgVI.svg",
        feedback: "Atmosphere",
        val: "atmosphere",
    },
];
